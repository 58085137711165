import React from 'react'
import Hero from "../components/Hero";
import con from "../assets/construction.jpg";
import  Navbar from "../components/Navbar";
import { useState } from "react";
import Popup from "../components/Popup";
import "../index.css";
import Footer from '../components/Footer';



function Construction() {
  const [buttonPopup, setButtonPopup] = useState(false);
  return (
    <div className='fixed-container'>
       <Navbar/>
        <Hero 
         cName="serv"
         HeroImg={con}
         title="Construction"
         text=""
         buttonText="Urgent"
         url="/urgent"
         btnClass="show"
        />
        <br></br><br></br>
        <div className="container">
          
              <p>Bienvenue chez <b> H.B Africa</b>, votre partenaire de confiance pour des services de construction exceptionnels. 
                Notre équipe d'experts propose une approche personnalisée, une expertise technique de haut niveau et des services complets, 
                allant de la conception à la réalisation. Nous nous engageons à dépasser vos attentes et à garantir la qualité, 
                la sécurité et la satisfaction client. Confiez-nous votre projet, et ensemble, nous concrétiserons vos visions de construction. 
                </p>
                <div className="card-group">
                 
                    <div className="card">
                      <img src={'cons0.png'} className="card-img-top " alt='fgh 1' />                  
                    </div>
                              
                  <div className="card">
                    <img src={'cons1.png'} className="card-img-top " alt='ghj 2' />
                  </div>
                          
                  <div className="card">
                    <img src={'cons2.png'} className="card-img-top" alt='fgh 3' />
                  </div>
               
                </div>
        <br></br>

        <div className="card-group">
                 
            <div className="card">
              <img src={'cons3.png'} className="card-img-top " alt='fgh 1' />
            </div>
              
            <div className="card">
              <img src={'cons4.png'} className="card-img-top" alt='ghj 2' />
            </div>
               
            <div className="card">
              <img src={'cons5.png'} className="card-img-top" alt='fgh 3' />
            </div>      

        </div>
        <br></br>

      <div className="card-group">
                
            <div className="card">
              <img src={'cons6.png'} className="card-img-top " alt='fgh 1' />
            </div>
                 
            <div className="card">
              <img src={'cons7.png'} className="card-img-top" alt='ghj 2' />
            </div>
                  
            <div className="card">
              <img src={'cons8.png'} className="card-img-top" alt='fgh 3' />
            </div>
      
        </div>

        <br></br>

        <div className="card-group">
                 
            <div className="card">
              <img src={'cons9.png'} className="card-img-top " alt='fgh 1' />
            </div>
               
            <div className="card">
              <img src={'cons10.png'} className="card-img-top" alt='ghj 2' />
            </div>
               
            <div className="card">
              <img src={'cons11.png'} className="card-img-top" alt='fgh 3' />
            </div>     

        </div>

        <br></br>

        <div className="card-group">
               
            <div className="card">
              <img src={'cons12.png'} className="card-img-top " alt='fgh 1' />
            </div>
        
            <div className="card">
              <img src={'cons13.png'} className="card-img-top" alt='ghj 2' />
            </div>
        
            <div className="card">
              <img src={'cons14.png'} className="card-img-top" alt='fgh 3' />
            </div>   

        </div>

        <br></br>
        <div className="card-group">
                
            <div className="card">
              <img src={'cons15.png'} className="card-img-top " alt='fgh 1' />
            </div>
              
            <div className="card">
              <img src={'cons16.png'} className="card-img-top" alt='ghj 2' />
            </div>
          
            <div className="card">
              <img src={'cons17.png'} className="card-img-top" alt='fgh 3' />
            </div>
  
        </div>
        <br></br>

        <div className="card-group">
              
            <div className="card">
              <img src={'cons18.png'} className="card-img-top " alt='fgh 1' />
            </div>
             
            <div className="card">
              <img src={'cons19.png'} className="card-img-top" alt='ghj 2' />
            </div>
           
            <div className="card">
              <img src={'cons20.png'} className="card-img-top" alt='fgh 3' />
            </div>

        </div>

        <br></br>
        <div className="card-group">
                
            <div className="card">
              <img src={'cons21.png'} className="card-img-top " alt='fgh 1' />
            </div>
        
            <div className="card">
              <img src={'cons22.png'} className="card-img-top" alt='ghj 2' />
            </div>
           
            <div className="card">
              <img src={'cons23.png'} className="card-img-top" alt='fgh 3' />
            </div>
  
        </div>
          
        </div>

        <div class="container mt-5">
              <div class="d-flex justify-content-center">
              <button onClick={() => setButtonPopup(true)} id="reservation-btn" type="button" className="my-button btn btn-primary">Réserver Maintenant</button>   
              </div>          
        </div>
        <Popup trigger={buttonPopup} setTrigger=
            {setButtonPopup} > 
          <h3>Réserver Service Construction</h3>
          <div className="input-box">
              <form action="https://formsubmit.co/contact@hbafrica.ma" method="POST" id="myForm">
              <div className="formbx">
                <div className="input-filed">
                  <input id="name" type="text" className="input" name="name"  required />
                  <label htmlFor="name">Nom et Prénoms : </label>
                </div>
                <div className="input-filed">
                <input id="tel" type="tel" className="input" name="number" required />
                  <label htmlFor="tel">Votre Teléphone : </label>
                </div>
                <div className="input-filed">
                  <input id="adresse" type="text" className="input" name="localisation" required />
                  <label htmlFor="adresse">Votre adresse : </label>
                </div>
                <div className="input-filed">
                              <select id="service" className="input" name="service" required>
                                <option value="Construction">Construction </option>                               
                              </select>
                              <label htmlFor="service">Sélectionnez le service :</label>
                            </div>
                <input type="hidden" name="_captcha" value='false'/>
                <input type="hidden" name="_next" value='https://www.hbafrica.ma/thanks'/>
                <input onclick="sendEmail()" type="submit" className="submit" value="envoyer"/>
              </div>
              </form> 
            </div> 
        </Popup>
      <Footer />
    </div>
  )
}
export default Construction;
