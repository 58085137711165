import React from 'react'
import Hero from "../components/Hero";
import int from "../assets/sec7.png";
import  Navbar from "../components/Navbar";
import { useState } from "react";
import Popup from "../components/Popup";
import "../index.css";
import Footer from '../components/Footer';


function SécurutéIntégré() {
  const [buttonPopup, setButtonPopup] = useState(false);
  return (
    <div className='fixed-container'>
       <Navbar/>
        <Hero 
         cName="serv"
         HeroImg={int}
         title="Sécurité Intégré"
         text=""
         buttonText="Urgent"
         url="/urgent"
         btnClass="show"
        />
        <br></br><br></br><br></br>
        <div className="container">
      <p>Bienvenue chez <b>H.B Africa </b>, votre partenaire de confiance en matière 
      de <b>sécurité intégrée</b> . Nous sommes fiers d'offrir des solutions complètes et 
      innovantes pour répondre à vos besoins de sécurité. Notre équipe expérimentée, 
      composée d'experts en sécurité, s'engage à assurer la protection de votre espace, 
      de vos biens et de vos personnes. Que ce soit pour des services de surveillance, 
      de gestion des accès, ou d'installation de systèmes de sécurité avancés, nous mettons en
       œuvre des technologies de pointe pour garantir une sécurité optimale. <br></br>
       Chez <b>H.B Africa </b>, nous comprenons l'importance cruciale de la tranquillité d'esprit, 
       c'est pourquoi nous travaillons sans relâche pour créer un environnement <b>sécurisé</b>, 
       permettant à nos clients de vaquer à leurs activités en toute confiance.</p>
       <br></br>
               
                <div className="card-group">
              
                      <div className="card">
                        <img src={'sec0.jpg'} className="card-img-top" alt='fgh 1' />                  
                      </div>
                         
                    <div className="card">
                      <img src={'sec1.jpg'} className="card-img-top img-fluid d-block w-100" alt='ghj 2' />
                    </div>
                         
                    <div className="card">
                      <img src={'sec2.jpg'} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
                    </div>
               
                </div>

                  <br></br><br></br>

                <div className="card-group">
                
                    <div className="card">
                      <img src={'sec3.jpg'} className="card-img-top img-fluid d-block w-100" alt='fgh 1' />
                    </div>
                             
                    <div className="card">
                      <img src={'sec4.jpg'} className="card-img-top img-fluid d-block w-100" alt='ghj 2' />
                    </div>
                      
                    <div className="card">
                      <img src={'sec5.jpg'} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
                    </div>
                
                </div>

              </div>
              
            <div class="container mt-5">
              <div class="d-flex justify-content-center">
                <button onClick={() => setButtonPopup(true)} id="reservation-btn" type="button" className="my-button btn btn-primary">Réserver Maintenant</button>   
              </div>                   
            </div>   

             <Popup trigger={buttonPopup} setTrigger=
            {setButtonPopup} > 
          <h3>Réserver Service de Sécurité Intégrée</h3>
          <div className="input-box">
              <form action="https://formsubmit.co/contact@hbafrica.ma" method="POST" id="myForm">
              <div className="formbx">
                <div className="input-filed">
                  <input id="name" type="text" className="input" name="name"  required />
                  <label htmlFor="name">Nom et Prénoms : </label>
                </div>
                <div className="input-filed">
                <input id="tel" type="tel" className="input" name="number" required />
                  <label htmlFor="tel">Votre Teléphone : </label>
                </div>
                <div className="input-filed">
                  <input id="adresse" type="text" className="input" name="localisation" required />
                  <label htmlFor="adresse">Votre adresse : </label>
                </div>
                <div className="input-filed">
                              <select id="service" className="input" name="service" required>
                                <option value="SécurutéIntégré">SécurutéIntégré </option>                               
                              </select>
                              <label htmlFor="service">Sélectionnez le service :</label>
                            </div>
                <input type="hidden" name="_captcha" value='false'/>
                <input type="hidden" name="_next" value='https://www.hbafrica.ma/thanks'/>
                <input onclick="sendEmail()" type="submit" className="submit" value="envoyer"/>
              </div>
              </form> 
            </div> 
        </Popup> 
        <Footer /> 
    </div>
  )
}
export default SécurutéIntégré;
