export const Menu =[
    {
        title :"Acceuil",
        url :"/",
        cName :"nav-links",
        icons :"fa-solid fa-house"
    },
    {
        title :"Services",
        url :"/services",
        cName :"nav-links",
        icons :"fa-solid fa-caret-down",
        subMenu: [
            {
                title: "Maintenance",
                url: "/maintenance",
                cName: "nav-sub-links",
            },
            {
                title: "Charpenterie",
                url: "/charpenterie",
                cName: "nav-sub-links",
            },
            {
                title: "Electricité",
                url: "/électricité",
                cName: "nav-sub-links",
            },
            {
                title: "Platrage",
                url: "/décore",
                cName: "nav-sub-links",
            },
            {
                title: "Construction",
                url: "/construction",
                cName: "nav-sub-links",
            },
            {
                title: "Peinture",
                url: "/peinture",
                cName: "nav-sub-links",
            },
            {
                title: " Ménage ",
                url: "/ménage",
                cName: "nav-sub-links",
            },
            {
                title: " gardiennage ",
                url: "/sécurité",
                cName: "nav-sub-links",
            },
            {
                title: "Sécuruté & Intégré",
                url: "/sécuruté_intégré",
                cName: "nav-sub-links",
            },
            {
                title: "Instalation Eléctrique",
                url: "/installation_électrique",
                cName: "nav-sub-links",
            },
            // Ajoutez d'autres services au besoin
        ]
    },
    {
        title :"Réclamation",
        url :"/reclamation",
        cName :"nav-links",
        icons :"fa-solid fa-circle-exclamation"
    },
    {
        title :"Contact",
        url :"/contact",
        cName :"nav-links",
        icons :"fa-sharp fa-solid fa-address-card"
    },
    {
        title :"Boutique",
        url :"/boutique",
        cName :"nav-links-mobile",
    },
    {
        title :"Espace Partenaire",
        url :"/signup",
        cName :"nav-links-mobile",
    }
]