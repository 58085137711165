import React from 'react'
import Hero from "../components/Hero";
import mein from "../assets/menage.png";
import  Navbar from "../components/Navbar";
import { useState } from "react";
import Popup from "../components/Popup";
import "../index.css";
import Footer from '../components/Footer';



function Ménage() {
  const [buttonPopup, setButtonPopup] = useState(false);
  return (
    <div className='fixed-container'>
       <Navbar/>
        <Hero 
         cName="serv"
         HeroImg={mein}
         title="Ménage"
         text=""
         buttonText="Urgent"
         url="/urgent"
         btnClass="show"
        />
        <br></br><br></br>
        <div className="container">
       
         <p>Bienvenue chez <b>H.B Africa</b>, où nous redéfinissons l'excellence en matière de services 
         de <b>ménage</b>. Notre équipe dévouée s'engage à fournir des services de nettoyage exceptionnels, 
         adaptés à vos besoins spécifiques. Que ce soit pour des espaces résidentiels ou commerciaux, 
         notre approche méticuleuse et notre personnel qualifié garantissent un environnement impeccable. 
         Nous comprenons l'importance de la propreté pour la santé et le bien-être, c'est pourquoi nous 
         offrons des solutions personnalisées, respectueuses de l'environnement et efficaces. 
         Faites confiance à <b>H.B Africa</b> pour créer un espace propre et accueillant, permettant à 
         nos clients de se concentrer sur l'essentiel.</p>
           
           <div className="card-group">
         
                 <div className="card">
                   <img src={'men0.png'} className="card-img-top" alt='fgh 1' />                  
                 </div>
                    
               <div className="card">
                 <img src={'men1.png'} className="card-img-top img-fluid d-block w-100" alt='ghj 2' />
               </div>
                             
           </div>

             <br></br><br></br>

           <div className="card-group">
                        
               <div className="card">
                 <img src={'men2.png'} className="card-img-top img-fluid d-block w-100" alt='ghj 2' />
               </div>
                 
               <div className="card">
                 <img src={'men3.png'} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
               </div>
           
           </div>

        </div>
         
        <div class="container mt-5">
          <div class="d-flex justify-content-center">
            <button onClick={() => setButtonPopup(true)} id="reservation-btn" type="button" className="my-button btn btn-primary">Réserver Maintenant</button>   
          </div>               
        </div>  

        <Popup trigger={buttonPopup} setTrigger=
            {setButtonPopup} > 
          <h3>Réserver Service de Ménage </h3>
          <div className="input-box">
              <form action="https://formsubmit.co/contact@hbafrica.ma" method="POST" id="myForm">
              <div className="formbx">
                <div className="input-filed">
                  <input id="name" type="text" className="input" name="name"  required />
                  <label htmlFor="name">Nom et Prénoms : </label>
                </div>
                <div className="input-filed">
                <input id="tel" type="tel" className="input" name="number" required />
                  <label htmlFor="tel">Votre Teléphone : </label>
                </div>
                <div className="input-filed">
                  <input id="adresse" type="text" className="input" name="localisation" required />
                  <label htmlFor="adresse">Votre adresse : </label>
                </div>
                <div className="input-filed">
                              <select id="service" className="input" name="service" required>
                                <option value="Menage">Menage </option>                               
                              </select>
                              <label htmlFor="service">Sélectionnez le service :</label>
                            </div>
                <input type="hidden" name="_captcha" value='false'/>
                <input type="hidden" name="_next" value='https://www.hbafrica.ma/thanks'/>
                <input onclick="sendEmail()" type="submit" className="submit" value="envoyer"/>
              </div>
              </form> 
            </div> 
          </Popup> 
      <Footer />
    </div>
  )
}
export default Ménage;
