import React from "react";
import { useState } from "react";
import Popup from "../components/Popup";
import Hero from "../components/Hero";
import  Navbar from "../components/Navbar"
import urgent from "../assets/urgg.png"
import "../index.css";
import Footer from "../components/Footer";


function Urgent (){
  const [buttonPopup, setButtonPopup] = useState(false);
    return(
       <div className="fixed-container">
        <Navbar/>
        <Hero 
         cName="serv"
         HeroImg={urgent}
         title=""
         text=""
         url="/urgent"

        />
         <div className='container'>  
        <h3>Voici un espace nos fideles clients qui ont un travaille urgent à faire </h3>
        <p>Votre satisfaction est notre priorité absolue, c'est pourquoi nous comprenons que certaines situations 
        exigent une réponse rapide et efficace. Si vous êtes confronté à un travail urgent, nous sommes là pour vous aider.
         Remplissez simplement le formulaire ci-dessous en détaillant vos besoins spécifiques et les délais impartis.
          Notre équipe dédiée traitera votre demande en priorité et vous contactera dans les plus brefs délais pour discuter 
          des options disponibles. Chez [H.B Africa], nous sommes prêts à répondre promptement à vos besoins urgents, 
          tout en assurant la qualité exceptionnelle de nos services. 
        Confiez-nous votre urgence, nous sommes là pour vous accompagner.</p>
       </div>
          <div className="container mt-5">
              <div className="d-flex justify-content-center">
                <button onClick={() => setButtonPopup(true)} id="reservation-btn" type="button" className="my-button btn btn-danger">Travail Urgent</button>   
              </div>            
          </div>

      <Popup trigger={buttonPopup} setTrigger=
            {setButtonPopup} > 
          <h1>Travail Urgent</h1>
          <div className="input-box">
              <form action="https://formsubmit.co/contact@hbafrica.ma" method="POST" id="myForm">
              <div className="formbx">
                <div className="input-filed">
                  <input id="name" type="text" className="input" name="name of urgent client"  required />
                  <label htmlFor="name">Nom et Prénoms : </label>
                </div>
                <div className="input-filed">
                <input id="tel" type="tel" className="input" name="number of urgent client" required />
                  <label htmlFor="tel">Votre Teléphone : </label>
                </div>
                <div className="input-filed">
                  <input id="adresse" type="text" className="input" name="location of urgent client" required />
                  <label htmlFor="adresse">Votre adresse : </label>
                </div>
                <div className="input-filed">
                              <select id="service" className="input" name="service" required>
                                <option value="Maintenance">Maintenance </option>
                                <option value="Charpenterie">Charpenterie </option>
                                <option value="Construction">Construction </option>
                                <option value="Eléctricité">Eléctricité </option>
                                <option value="Peinture">Peinture </option>
                                <option value="Instalation Eléctrique">Instalation Eléctrique </option>
                                <option value="Ménage">Ménage </option>
                                <option value="Sécurité Intégré">Sécurité Intégré </option>
                                <option value="Platrage">Platrage </option>
                                <option value="Gardiennage">Gardiennage </option>
                              </select>
                              <label htmlFor="service">Sélectionnez le service :</label>
                            </div>
                <input type="hidden" name="_captcha" value='false'/>
                <input type="hidden" name="_next" value='https://www.hbafrica.ma/thanks'/>
                <input onclick="sendEmail()" type="submit" className="submit" value="envoyer"/>
              </div>
              </form> 
            </div> 
        </Popup>
        <Footer />

       </div>
    )   
}


export default Urgent;