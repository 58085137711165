import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import "./Footer.css"

function Footer() {
  return (
  
    <MDBFooter bgColor='light' className='foo text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Connectez Nous Via Nos Socials Medias  :</span>
        </div>

        <div>
          <a href='https://web.facebook.com/profile.php?id=100075931994524&mibextid=YMEMSu&_rdc=1&_rdr' className='me-4 text-reset'>
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href='https://wa.me/212665603800' className='me-4 text-reset'>
            <MDBIcon fab icon="whatsapp" />
          </a>
          <a href='https://www.instagram.com/hb.africa/' className='me-4 text-reset'>
            <MDBIcon fab icon="instagram" />
          </a>
          {/* <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="email" />
          </a> */}
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="linkedin" />
          </a>
          <a href='https://www.tiktok.com/@hb.africa?_t=8j20WTyaW29&_r=1' className='me-4 text-reset'>
            <MDBIcon fab icon="tiktok" />
          </a>
        </div>
      </section>

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="6" className='mx-auto mb-4'>

              <h4 className='hblogo'>
                <img src={'logo2.png'} alt="Logo" className="logo" />
                H.B Africa s.a.r.l
              </h4>
           
            </MDBCol>

            {/* <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
              <p>
                <a href='#!' className='text-reset'>
                  Angular
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  React
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Vue
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Laravel
                </a>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
                <a href='#!' className='text-reset'>
                  Pricing
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Settings
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Orders
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Help
                </a>
              </p>
            </MDBCol> */}

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                Fahs Anjra, 2212 Quartier Bni Massaoud Commune El Bahraouiyeinne - Tanger-Médina (AR)
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                contact@hbafrica.ma
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> + 212 661-144369
              </p>
            
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-1' style={{ backgroundColor: 'blue' }}>
      <p>
        @{new Date().getFullYear()} H.B Africa. All Right reserved. 
      </p>
      </div>
    </MDBFooter>

 
  );
}

export default Footer