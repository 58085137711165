import React from 'react'
import Hero from "../components/Hero";
import dec from "../assets/dec11.png";
import  Navbar from "../components/Navbar";
import { useState } from "react";
import Popup from "../components/Popup";
import "../index.css";
import Footer from '../components/Footer';



function Decore() {
  const [buttonPopup, setButtonPopup] = useState(false);
  return (
    <div className='fixed-container'>
       <Navbar/>
        <Hero 
         cName="serv"
         HeroImg={dec}
         title="Décoration"
         text=""
         buttonText="Urgent"
         url="/urgent"
         btnClass="show"
        />
        <br></br><br></br>
        <div className="container">
              <p><b>H.B Africa </b> vous présente un service de <b>décoration</b> qui peux transformer votre espace avec créativité et expertise, offrant des designs personnalisés pour refléter votre style unique. Créez une ambiance exceptionnelle avec notre équipe de décorateurs passionnés.</p>
           
        <br></br>
        <div className="card-group">
                
            <div className="card">
              <img src={'dec6.png'} className="card-img-top " alt='fgh 1' />
            </div>
           
            <div className="card">
              <img src={'dec8.png'} className="card-img-top" alt='ghj 2' />
            </div>
                
            <div className="card">
              <img src={'d21.png'} className="card-img-top" alt='fgh 3' />
            </div>     

        </div>
      <br></br>
      <div className="card-group">
                  
            <div className="card">
              <img src={'dec1.png'} className="card-img-top " alt='fgh 1' />
            </div>
                
            <div className="card">
              <img src={'dec7.png'} className="card-img-top" alt='ghj 2' />
            </div>
         
            <div className="card">
              <img src={'dec11.png'} className="card-img-top" alt='fgh 3' />
            </div>     

      </div>
        <br></br>
        <div className="card-group">
                
            <div className="card">
              <img src={'dec16.png'} className="card-img-top " alt='fgh 1' />
            </div>
        
            <div className="card">
              <img src={'dec15.png'} className="card-img-top" alt='ghj 2' />
            </div>
               
            <div className="card">
              <img src={'dec14.png'} className="card-img-top" alt='fgh 3' />
            </div>  

        </div>
        <br></br>
        <div className="card-group">               
            <div className="card">
              <img src={'d0.png'} className="card-img-top " alt='fgh 1' />
            </div>
           
            <div className="card">
              <img src={'d1.png'} className="card-img-top" alt='ghj 2' />
            </div>
                
            <div className="card">
              <img src={'d2.png'} className="card-img-top" alt='fgh 3' />
            </div>     
        </div>
        <br></br>
        <div className="card-group">               
            <div className="card">
              <img src={'d3.png'} className="card-img-top " alt='fgh 1' />
            </div>
           
            <div className="card">
              <img src={'d4.png'} className="card-img-top" alt='ghj 2' />
            </div>
                
            <div className="card">
              <img src={'d5.png'} className="card-img-top" alt='fgh 3' />
            </div>     
        </div>
        <br></br>
        <div className="card-group">               
            <div className="card">
              <img src={'d6.png'} className="card-img-top " alt='fgh 1' />
            </div>
           
            <div className="card">
              <img src={'d7.png'} className="card-img-top" alt='ghj 2' />
            </div>
                
            <div className="card">
              <img src={'d8.png'} className="card-img-top" alt='fgh 3' />
            </div>     
        </div>
        <br></br>
        <div className="card-group">               
            <div className="card">
              <img src={'d9.png'} className="card-img-top " alt='fgh 1' />
            </div>
           
            <div className="card">
              <img src={'d10.png'} className="card-img-top" alt='ghj 2' />
            </div>
                
            <div className="card">
              <img src={'d11.png'} className="card-img-top" alt='fgh 3' />
            </div>     
        </div>
        <br></br>
        <div className="card-group">               
            <div className="card">
              <img src={'d12.png'} className="card-img-top " alt='fgh 1' />
            </div>
           
            <div className="card">
              <img src={'d13.png'} className="card-img-top" alt='ghj 2' />
            </div>
                
            <div className="card">
              <img src={'d14.png'} className="card-img-top" alt='fgh 3' />
            </div>     
        </div>
        <br></br>
        <div className="card-group">               
            <div className="card">
              <img src={'d15.png'} className="card-img-top " alt='fgh 1' />
            </div>
           
            <div className="card">
              <img src={'d16.png'} className="card-img-top" alt='ghj 2' />
            </div>
                
            <div className="card">
              <img src={'d17.png'} className="card-img-top" alt='fgh 3' />
            </div>     
        </div>
        <br></br>
        <div className="card-group">               
            <div className="card">
              <img src={'d18.png'} className="card-img-top " alt='fgh 1' />
            </div>
           
            <div className="card">
              <img src={'d19.png'} className="card-img-top" alt='ghj 2' />
            </div>
                
            <div className="card">
              <img src={'d20.png'} className="card-img-top" alt='fgh 3' />
            </div>     
        </div>

      <br></br>
         
      </div>
      <div class="container mt-5">
        <div class="d-flex justify-content-center">
          <button onClick={() => setButtonPopup(true)} id="reservation-btn" type="button" className="my-button btn btn-primary">Réserver Maintenant</button>   
        </div>           
      </div>
      <Popup trigger={buttonPopup} setTrigger=
            {setButtonPopup} > 
          <h3>Réserver Service décorateurs </h3>
          <div className="input-box">
              <form action="https://formsubmit.co/contact@hbafrica.ma" method="POST" id="myForm">
              <div className="formbx">
                <div className="input-filed">
                  <input id="name" type="text" className="input" name="name"  required />
                  <label htmlFor="name">Nom et Prénoms : </label>
                </div>
                <div className="input-filed">
                <input id="tel" type="tel" className="input" name="number" required />
                  <label htmlFor="tel">Votre Teléphone : </label>
                </div>
                <div className="input-filed">
                  <input id="adresse" type="text" className="input" name="localisation" required />
                  <label htmlFor="adresse">Votre adresse : </label>
                </div>
                <div className="input-filed">
                              <select id="service" className="input" name="service" required>
                                <option value="Platrage">Platrage </option>                               
                              </select>
                              <label htmlFor="service">Sélectionnez le service :</label>
                            </div>
                <input type="hidden" name="_captcha" value='false'/>
                <input type="hidden" name="_next" value='https://www.hbafrica.ma/thanks'/>
                <input onclick="sendEmail()" type="submit" className="submit" value="envoyer"/>
              </div>
              </form> 
            </div> 
        </Popup>
       <Footer/>
    </div>
  )
}
export default Decore;
