import Footer from "../components/Footer";
import Hero from "../components/Hero";
import  Navbar from "../components/Navbar";
import Services from "../components/Services";

function Home (){
    return(
       <div className="fixed-container">
        <Navbar/>
        <Hero 
         cName="hero"
         HeroImg="https://images.unsplash.com/photo-1683115100702-af818681184d?auto=format&fit=crop&q=80&w=1776&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
         title="Bienvenue à H.B Africa"
         text="Choisissez Votre formidable choix"
         buttonText="Urgent"
         url="/urgent"
         btnClass="show"
        />
        <Services/>
        <Footer />
       </div>
    )   
}

 
export default Home;