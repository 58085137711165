import React  from 'react'
import { useState } from "react";
import Hero from "../components/Hero";
import maint from "../assets/maint.jpg";
import  Navbar from "../components/Navbar";
import "../index.css";
import Popup from "../components/Popup";
import Footer from '../components/Footer';

function Maintenance() {
  const [buttonPopup, setButtonPopup] = useState(false);

  return (
    <div className='fixed-container'>
       <Navbar/>
        <Hero 
         cName="serv"
         HeroImg={maint}
         title="Maintenance" style={{color: "red" }} 
         text=""
         buttonText="Urgent"
         url="/urgent"
         btnClass="show"
        />
        <br></br><br></br>

        <div className="container">        
                <p> <b>H.B Africa </b> vous présente un service de <b>maintenance </b> préventive et curative qui assure le bon fonctionnement des équipements industrielles 
                ainsi que la réparation , comme nous proposons des solutions complètes:</p>
                <ul>
                  ◇Le Bobinage .<br></br>
                  ◇La Réparation et fabrication mecanique <br></br>
                  ◇Le raccordement des equipement electrique a mis en service<br></br>
                </ul> 
                <p>Ainsi nous vous proposons le dépannage, l’entretien et la maintenance de vos machines, systèmes et pièces pneumatiques et hydrolique.</p> 
   
                  <div className="card-group">
                      <div className="card">
                        <img src={'maint06.png'} className="card-img-top" alt='fgh 1' />                  
                      </div>
              
                      <div className="card">
                        <img src={'maint07.jpg'} className="card-img-top " alt='ghj 2' />
                      </div>
                  </div>   
                  <br></br>     
                  <div className="card-group">
                      <div className="card">
                        <img src={'m0.jpg'} className="card-img-top" alt='fgh 1' />                  
                      </div>
              
                      <div className="card">
                        <img src={'m1.jpg'} className="card-img-top img-fluid d-block w-100" alt='ghj 2' />
                      </div>
                  </div> 
                  <br></br>
                  <div className="card-group">
                      <div className="card">
                        <img src={'m2.jpg'} className="card-img-top" alt='fgh 1' />                  
                      </div>
              
                      <div className="card">
                        <img src={'m3.jpg'} className="card-img-top img-fluid d-block w-100" alt='ghj 2' />
                      </div>
                  </div> 
                  <br></br>
                  <div className="card-group">
                      <div className="card">
                        <img src={'m4.jpg'} className="card-img-top" alt='fgh 1' />                  
                      </div>
              
                      <div className="card">
                        <img src={'m5.jpg'} className="card-img-top img-fluid d-block w-100" alt='ghj 2' />
                      </div>
                  </div> 
                  <br></br>
                  <div className="card-group">
                      <div className="card">
                        <img src={'mainte1.jpg'} className="card-img-top" alt='fgh 1' />                  
                      </div>
              
                      <div className="card">
                        <img src={'mainte2.jpg'} className="card-img-top img-fluid d-block w-100" alt='ghj 2' />
                      </div>
                  </div>
        <br></br>
    
 
        </div>

          <div className="container mt-5">
              <div className="d-flex justify-content-center">
              <button onClick={() => setButtonPopup(true)} id="reservation-btn" type="button" className="my-button btn btn-primary">Réserver Maintenant</button>   
              </div>            
          </div>
          <Popup trigger={buttonPopup} setTrigger=
            {setButtonPopup} > 
          <h3>Réserver Service de Maintenance</h3>
          <div className="input-box">
              <form action="https://formsubmit.co/contact@hbafrica.ma" method="POST" id="myForm">
              <div className="formbx">
                <div className="input-filed">
                  <input id="name" type="text" className="input" name="name"  required />
                  <label htmlFor="name">Nom et Prénoms : </label>
                </div>
                <div className="input-filed">
                <input id="tel" type="tel" className="input" name="number" required />
                  <label htmlFor="tel">Votre Teléphone : </label>
                </div>
                <div className="input-filed">
                  <input id="adresse" type="text" className="input" name="localisation" required />
                  <label htmlFor="adresse">Votre adresse : </label>
                </div>
                <div className="input-filed">
                              <select id="service" className="input" name="service" required>
                                <option value="Maintenance">Maintenance </option>                               
                              </select>
                              <label htmlFor="service">Sélectionnez le service :</label>
                            </div>
                <input type="hidden" name="_captcha" value='false'/>
                <input type="hidden" name="_next" value='https://www.hbafrica.ma/thanks'/>
                <input onclick="sendEmail()" type="submit" className="submit" value="envoyer"/>
              </div>
              </form> 
            </div> 
        </Popup>

<Footer />
   
  </div>
  )
}
export default Maintenance;
