import React from 'react'
import Hero from "../components/Hero";
import char from "../assets/char.jpg";
import  Navbar from "../components/Navbar";
import { useState } from "react";
import Popup from "../components/Popup";
import "../index.css";
import Footer from '../components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

 

function Charpenterie() {
  const [buttonPopup, setButtonPopup] = useState(false);

  return (
    <div className='fixed-container'>
       <Navbar/>
        <Hero 
         cName="serv"
         HeroImg={char}
         title="Charpenterie"
         text=""
         buttonText="Urgent"
         url="/urgent"
         btnClass="show"
        />
        <br></br><br></br>
        
        <div className="container">

                <p><b>H.B Africa</b> offre des services de <b> charpenterie </b>de haute qualité, allant de la conception à l'installation, avec un engagement envers la durabilité et la satisfaction client. Contactez-nous pour des solutions de charpenterie sur mesure.</p>
                <div className="card-group">
               
                    <div className="card">
                      <img src={'charp1.jpg'} className="card-img-top img-fluid d-block w-100" alt='fgh 1' />                  
                    </div>
                            
                  <div className="card">
                    <img src={'charp2.jpg'} className="card-img-top img-fluid d-block w-100" alt='ghj 2' />
                  </div>
             
                  <div className="card">
                    <img src={'charp3.jpg'} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
                  </div>
             

                </div>
        <br></br>

        <div className="card-group">
                 
            <div className="card">
              <img src={'charp4.png'} className="card-img-top img-fluid d-block w-100" alt='fgh 1' />
            </div>
       
            <div className="card">
              <img src={'charp5.png'} className="card-img-top img-fluid d-block w-100" alt='ghj 2' />
            </div>
       
            <div className="card">
              <img src={'charp6.png'} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
            </div>
       
        </div>

        </div>
   
          <div class="container mt-5">
              <div class="d-flex justify-content-center">
                <button onClick={() => setButtonPopup(true)} id="reservation-btn" type="button" className="my-button btn btn-primary">Réserver Maintenant </button>   
              </div>          
          </div>
          <Popup trigger={buttonPopup} setTrigger=
            {setButtonPopup} > 
          <h3>Réserver Service Charpenterie </h3>
          <div className="input-box">
              <form action="https://formsubmit.co/contact@hbafrica.ma" method="POST" id="myForm">
              <div className="formbx">
                <div className="input-filed">
                  <input id="name" type="text" className="input" name="name"  required />
                  <label htmlFor="name">Nom et Prénoms : </label>
                </div>
                <div className="input-filed">
                <input id="tel" type="tel" className="input" name="number" required />
                  <label htmlFor="tel">Votre Teléphone : </label>
                </div>
                <div className="input-filed">
                  <input id="adresse" type="text" className="input" name="localisation" required />
                  <label htmlFor="adresse">Votre adresse : </label>
                </div>
                <div className="input-filed">
                              <select id="service" className="input" name="service" required>
                                <option value="Charpenterie">Charpenterie </option>                               
                              </select>
                              <label htmlFor="service">Sélectionnez le service :</label>
                </div>
                <input type="hidden" name="_captcha" value='false'/>
                <input type="hidden" name="_next" value='https://www.hbafrica.ma/thanks'/>
                <input onclick="sendEmail()" type="submit" className="submit" value="envoyer"/>
              </div>
              </form> 
            </div> 
        </Popup>


      <Footer />
      
    </div>
  )
}
export default Charpenterie;
