import Hero from "../components/Hero";
import  Navbar from "../components/Navbar";
import contact from "../assets/cont (2).jpg";
import "../index.css";
import Footer from "../components/Footer";


function Contact (){
    return(
       <div className="fixed-container" style={{textAlign:'center'}}>
        <Navbar/>
        <Hero 
         cName="serv"
         HeroImg={contact}
         title="Contact"
         
         buttonText="Urgent"
         url="/urgent"
         btnClass="show"
        />
        <h2>Contactez Nous</h2>
        <p>Nous sommes enchantés de pouvoir établir un contact avec nos précieux clients. Vos questions, commentaires et suggestions sont les bienvenus. Notre équipe dévouée est là pour vous offrir une expérience exceptionnelle. Merci de faire partie de notre communauté</p>
        <br></br>
        <div className="wrapper">
          <div className="container">

            <div className="input-box">
              <form action="https://formsubmit.co/contact@hbafrica.ma" method="POST" id="myForm">
              <div className="formbx">
                <div className="input-filed">
                  <input id="name" type="text" className="input" name="name"  required />
                  <label htmlFor="name">Nom et Prénoms : </label>
                </div>
                <div className="input-filed">
                <input id="tel" type="tel" className="input" name="number" required />
                  <label htmlFor="tel">Votre Teléphone : </label>
                </div>
                <div className="input-filed">
                  <input id="adresse" type="text" className="input" name="loca" required />
                  <label htmlFor="adresse">Votre adresse : </label>
                </div>
                <div className="input-filed">
                <textarea id="body" className="input" name="message" required/>
                  <label htmlFor="nabodyme">Votre Message : </label>
                </div>
                <input type="hidden" name="_captcha" value='false'/>
                <input type="hidden" name="_next" value='https://www.hbafrica.ma/thanks'/>
                <input onclick="sendEmail()" type="submit" className="submit" value="envoyer"/>
              </div>
              </form> 
            </div> 
          </div>
        </div>
     


       <Footer />
       
       </div>
    )   
}

export default Contact;