import React, { Component } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { Menu } from "./Menu";

class Navbar extends Component {
  state = {
    clicked: false,
    servicesMenuOpen: false, // État pour gérer l'affichage du sous-menu Services
  };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  // Fonction pour basculer l'état du sous-menu Services
  toggleServicesMenu = () => {
    this.setState({ servicesMenuOpen: !this.state.servicesMenuOpen });
  };

  componentDidMount() {
    // Add an event listener to close the Services dropdown when clicking outside of it
    document.addEventListener("click", this.handleDocumentClick);
  }

  componentWillUnmount() {
    // Remove the event listener when the component unmounts
    document.removeEventListener("click", this.handleDocumentClick);
  }

  handleDocumentClick = (event) => {
    if (
      this.state.servicesMenuOpen &&
      !event.target.closest(".services-submenu") &&
      !event.target.closest(".nav-links")
    ) {
      // Close the Services dropdown if the user clicked outside of it
      this.setState({ servicesMenuOpen: false });
    }
  };

  render() {
    return (
      <nav className="NavbarItems">
        <img src="logo2.png" alt="Logo" className="navbar-logo-image" />
        <h1 className="navbar-logo"> <Link to="/" className="costumlink">HB.Africa</Link></h1>
        <div className="menu-icons" onClick={this.handleClick}>
          <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>

        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
          {Menu.map((item, index) => {
            if (item.title === "Services") {
              return (
                <li key={index}>
                  <div
                    className="nav-links"
                    onClick={this.toggleServicesMenu}
                  >
                    <i className={item.icons}></i>
                    {item.title}
                    {this.state.servicesMenuOpen && (
                      <ul className="services-submenu">
                        {item.subMenu.map((subItem, subIndex) => (
                          <li key={subIndex}>
                            <Link
                              className={subItem.cName}
                              to={subItem.url}
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              {subItem.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </li>
              );
            } else {
              return (
                <li key={index}>
                  <Link className={item.cName} to={item.url}>
                    <i className={item.icons}></i>
                    {item.title}
                  </Link>
                </li>
              );
            }
          })}
        </ul>
        <div className="signup-button">
        <Link to="/boutique">
          <button>Boutique</button>
        </Link>  
        </div>
        <div className="signup-button">
        <Link to="/signup">
          <button>Espace Partenaire</button>
        </Link>  
        </div>
      </nav>
    );
  }
}

export default Navbar;
