import React from 'react'
import Hero from "../components/Hero";
import ins from "../assets/000.png";
import  Navbar from "../components/Navbar";
import { useState } from "react";
import Popup from "../components/Popup";
import "../index.css";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import Footer from '../components/Footer';



function InstallationElectrique() {
  const [buttonPopup, setButtonPopup] = useState(false);
  return (
    <div className='fixed-container'>
       <Navbar/>
        <Hero 
         cName="serv"
         HeroImg={ins}
         title="Instalation Electrique"
         text=""
         buttonText="Urgent"
         url="/urgent"
         btnClass="show"
        />
        <br></br><br></br>
         <div className="container">
              <p>L'installation électrique dans une maison ou un bâtiment est un système complexe de câblage et de composants électriques qui permet la distribution de l'électricité pour alimenter des appareils et des équipements, comme elle contient plusieur axes :</p>
                <ul>
                ◇ fourniture et pose chemin de câble y compris accessoires de pose.<br></br>
                  ◇ fourniture et pose tableau électrique <br></br>
                  ◇fourniture et tirage de câble.<br></br>
                  ◇ mise en service<br></br>
                </ul> 
                <div className="card-group">
              
                      <div className="card">
                        <img src={'inst0.png'} className="card-img-top" alt='fgh 1' />                  
                      </div>
                         
                    <div className="card">
                      <img src={'inta1.jpg'} className="card-img-top img-fluid d-block w-100" alt='ghj 2' />
                    </div>
                         
                    <div className="card">
                      <img src={'inta2.jpg'} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
                    </div>
               
                </div>

                  <br></br><br></br>

                <div className="card-group">
                
                    <div className="card">
                      <img src={'inta3.jpg'} className="card-img-top img-fluid d-block w-100" alt='fgh 1' />
                    </div>
                             
                    <div className="card">
                      <img src={'inta4.jpg'} className="card-img-top img-fluid d-block w-100" alt='ghj 2' />
                    </div>
                      
                    <div className="card">
                      <img src={'inta5.jpg'} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
                    </div>
                
                </div>

              </div>
              
            <div class="container mt-5">
              <div class="d-flex justify-content-center">
                <button onClick={() => setButtonPopup(true)} id="reservation-btn" type="button" className="my-button btn btn-primary">Réserver Maintenant</button>   
              </div>                   
            </div>   

             <Popup trigger={buttonPopup} setTrigger=
            {setButtonPopup} > 
          <h3>Réserver Service d'Instalation Electrique </h3>
          <div className="input-box">
              <form action="https://formsubmit.co/contact@hbafrica.ma" method="POST" id="myForm">
              <div className="formbx">
                <div className="input-filed">
                  <input id="name" type="text" className="input" name="name"  required />
                  <label htmlFor="name">Nom et Prénoms : </label>
                </div>
                <div className="input-filed">
                <input id="tel" type="tel" className="input" name="number" required />
                  <label htmlFor="tel">Votre Teléphone : </label>
                </div>
                <div className="input-filed">
                  <input id="adresse" type="text" className="input" name="localisation" required />
                  <label htmlFor="adresse">Votre adresse : </label>
                </div>
                <div className="input-filed">
                              <select id="service" className="input" name="service" required>
                                <option value="InstallationElectrique">InstallationElectrique </option>                               
                              </select>
                              <label htmlFor="service">Sélectionnez le service :</label>
                            </div>
                <input type="hidden" name="_captcha" value='false'/>
                <input type="hidden" name="_next" value='https://www.hbafrica.ma/thanks'/>
                <input onclick="sendEmail()" type="submit" className="submit" value="envoyer"/>
              </div>
              </form> 
            </div> 
        </Popup>  
        <Footer />
    </div>
  )
}
export default InstallationElectrique;
