import React from 'react'
import Hero from "../components/Hero";
import  Navbar from "../components/Navbar";
import nav from "../assets/sss.png";
import "../index.css";
import Footer from '../components/Footer';


function SignUp() {
  return (
    <div className='fixed-container'>
        <Navbar/>
        <Hero 
         cName="serv"
         HeroImg={nav}
         title=""
         text=""
        
        />
        <br></br><br></br>

        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="mx-auto my-auto">
                <h2>Rejoindre notre équipe</h2>
                <p>Nous sommes ravis de vous accueillir dans notre communauté grandissante chez <b>HB.Africa. </b> 
                Si vous êtes un professionnel passionné à la recherche d'opportunités stimulantes et d'un environnement 
                de travail collaboratif, vous êtes au bon endroit. Rejoignez notre équipe dévouée et contribuez à façonner 
                l'avenir de notre entreprise. En tant que membre de notre équipe, vous aurez accès à des projets innovants, 
                à des opportunités de croissance professionnelle et à une culture d'entreprise qui valorise la créativité et l'excellence. 
                Remplissez simplement le formulaire ci-dessous pour commencer votre voyage avec nous. 
                Nous sommes impatients de vous accueillir et de construire ensemble un avenir brillant
                </p>              
              </div>
            </div>
            <div className='col-md-6'>
              <div className="mx-auto my-auto" style={{ textAlign: 'right' }}>
              <h2>انضم إلى فريقنا</h2>
              <p>
              يسعدنا أن نرحب بكم في مجتمعنا المتنامي إذا كنت محترفًا شغوفًا وتبحث عن فرص مليئة بالتحديات وبيئة عمل تعاونية
                فقد وصلت إلى المكان الصحيح . انضم إلى فريقنا المتفاني وساعد في تشكيل مستقبل شركتنا 
              كعضو في فريقنا، سيكون لديك إمكانية الوصول إلى المشاريع المبتكرة وفرص النمو المهني وثقافة الشركة التي تقدر الإبداع والتميز. 
              ما عليك سوى ملء النموذج أدناه لبدء رحلتك معنا. ونحن نتطلع إلى الترحيب بكم وبناء مستقبل مشرق معا
              </p>
              </div>
            </div>
            <div className="wrapper col-12">
                  <div className="container">
                    <div className="input-box form-container">
                      <form action="https://formsubmit.co/contact@hbafrica.ma" method="POST" id="myForm">
                        <div className="formbx">
                          <div className="input-filed">
                            <input id="name" type="text" className="input" name="name of new partner"  required />
                            <label htmlFor="name">Nom et Prénoms : </label>
                          </div>
                          <div className="input-filed">
                          <input id="tel" type="tel" className="input" name="number of new partner " required />
                            <label htmlFor="tel">Votre Teléphone : </label>
                          </div>
                          <div className="input-filed">
                            <input id="adresse" type="text" className="input" name="location of partner" required />
                            <label htmlFor="adresse">Votre adresse : </label>
                          </div>
                          <h6>Service que vous Souhaitez travailer :</h6>
                            <div className="input-filed">
                              <select id="service" className="input" name="service" required>
                                <option value="Service Maintenance">Maintenance </option>
                                <option value="Service Charpenterie">Charpenterie </option>
                                <option value="Service Construction">Construction </option>
                                <option value="Service Eléctricité">Eléctricité </option>
                                <option value="Service Peinture">Peinture </option>
                                <option value="Service Instalation Eléctrique">Instalation Eléctrique </option>
                                <option value="Service Ménage">Ménage </option>
                                <option value="Service Sécurité Intégré">Sécurité Intégré </option>
                                <option value="Service Platrage">Platrage </option>
                                <option value="Service Gardiennage">Gardiennage </option>
                              </select>
                              <label htmlFor="service">Sélectionnez le service :</label>
                            </div>
                          <input type="hidden" name="_captcha" value='false'/>
                          <input type="hidden" name="_next" value='https://www.hbafrica.ma/thanks'/>
                          <input onclick="sendEmail()" type="submit" className="submit" value="envoyer"/>
                        </div>
                      </form> 
                    </div> 
                  </div>
                </div>
         </div>
      </div>
      <Footer />
    </div>
  )
}

export default SignUp

