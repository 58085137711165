import React from 'react'
import "../index.css";
import  Navbar from "../components/Navbar"
import Footer from '../components/Footer';


function Thanks (){
    return(
       <div>
            <Navbar/>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <img src={'thanks.png'} className="image-boutique" alt='ghj 2' />

            <Footer />
            
       </div>
    )   
}

export default Thanks;