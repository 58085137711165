import React from 'react'
import { useState } from "react";
import bout from "../assets/bout.png";
import Hero from "../components/Hero";
import Popup from "../components/Popup";
import  Navbar from "../components/Navbar";
import "../index.css";
import Footer from '../components/Footer';


function Shop() {
  const [buttonPopup, setButtonPopup] = useState(false);

  return (
    <div className='fixed-container'>
       <Navbar/>
        <Hero 
         cName="serv"
         HeroImg={bout}
         title=""
         text=""
         buttonText="Urgent"
         url="/urgent"
         btnClass="show"
        />
        <br></br><br></br>
        <div className='container'>  
  
          <h3>Ma Boutique</h3>
          <p>Nous sommes fiers de vous offrir une sélection exceptionnelle de produits électriques pour répondre à tous vos besoins. 
            Que vous soyez un professionnel du secteur ou un passionné cherchant des solutions fiables, notre gamme complète comprend des câbles, 
            des interrupteurs, des prises, des luminaires et bien plus encore. Chaque produit est soigneusement sélectionné pour sa qualité, 
            sa durabilité et sa performance exceptionnelle. Parcourez notre catalogue pour trouver les dernières innovations et les marques renommées dans le domaine de l'électricité. 
            Simplifiez vos projets électriques avec notre matériel de pointe, conçu pour vous offrir sécurité et efficacité. Faites de <b>H.B Africa</b> votre partenaire 
            de confiance pour tous vos besoins en matériel électrique.</p>
            <ul>
              ◇Sélectionner<b>la Matricule</b> du Matériéle que vous voulez acheter.<br></br>
              ◇Cliquer Sur le Boutton <b>Réserver Maintenant</b> au dessous des matériels <br></br>
              ◇Remplissez <b>La formulaire </b>que vous apparaît<br></br>
              ◇Un responsable vous <b> contactera </b>pour confirmez votre demande<br></br>
            </ul> 
            
          <div className='card-group'>         
                <div className='card'> 
                      <img src={'b0.png'} className="card-img-top" alt='ghj 2' />
                      <div class="card-body">
                        {/* <h5 class="card-title">Titre de la carte</h5> */}
                        <p class="card-text"><b>Matricule : </b></p>
                      </div>
                </div>  
                
                <div className='card'> 
                      <img src={'b1.png'} className="card-img-top" alt='ghj 2' />
                      <div class="card-body">
                        {/* <h5 class="card-title">Titre de la carte</h5> */}
                        <p class="card-text"><b>Matricule : </b></p>
                      </div>
                </div>  
           
                <div className='card'> 
                      <img src={'b2.png'} className="card-img-top" alt='ghj 2' />
                      <div class="card-body">
                        {/* <h5 class="card-title">Titre de la carte</h5> */}
                        <p class="card-text"><b>Matricule : </b></p>
                      </div>
                  </div>  
          
                <div className='card'> 
                      <img src={'b3.png'} className="card-img-top" alt='ghj 2' />
                      <div class="card-body">
                        {/* <h5 class="card-title">Titre de la carte</h5> */}
                        <p class="card-text"><b>Matricule : </b></p>
                      </div>
                </div>        
          </div>

          <br></br>

          <div className='card-group'> 
      
            <div className='card'> 
                 <img src={'b4.png'} className="card-img-top" alt='ghj 2' />
                 <div class="card-body">
                   {/* <h5 class="card-title">Titre de la carte</h5> */}
                   <p class="card-text"><b>Matricule : </b></p>
                 </div>
            </div>
               
            <div className='card'> 
                  <img src={'b5.png'} className="card-img-top" alt='ghj 2' />
                  <div class="card-body">
                    {/* <h5 class="card-title">Titre de la carte</h5> */}
                    <p class="card-text"><b>Matricule : </b></p>
                  </div>
            </div>    
             
            <div className='card'> 
                 <img src={'b6.png'} className="card-img-top" alt='ghj 2' />
                 <div class="card-body">
                   {/* <h5 class="card-title">Titre de la carte</h5> */}
                   <p class="card-text"><b>Matricule : </b></p>
                 </div>
            </div>
            
            <div className='card'>  
                <img src={'b7.png'} className="card-img-top" alt='ghj 2' />
                <div class="card-body">
                  {/* <h5 class="card-title">Titre de la carte</h5> */}
                  <p class="card-text"><b>Matricule : </b></p>
                </div>
            </div>                 
          </div>

        <br></br>

        <div className='card-group'> 
          
          <div className='card'> 
             <img src={'b8.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
                {/* <h5 class="card-title">Titre de la carte</h5> */}
                <p class="card-text"><b>Matricule : </b></p>
              </div>
          </div>
          
          <div className='card'> 
              <img src={'b9.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
                {/* <h5 class="card-title">Titre de la carte</h5> */}
                <p class="card-text"><b>Matricule : </b></p>
              </div>
          </div>    
              
          <div className='card'> 
              <img src={'b10.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
                {/* <h5 class="card-title">Titre de la carte</h5> */}
                <p class="card-text"><b>Matricule : </b></p>
              </div>
          </div>    
              
          <div className='card'> 
              <img src={'b11.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
                {/* <h5 class="card-title">Titre de la carte</h5> */}
                <p class="card-text"><b>Matricule : </b></p>
              </div>  
          </div>               

        </div>
        <br></br>

        <div className='card-group'> 
         
          <div className='card'> 
              <img src={'b12.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
                {/* <h5 class="card-title">Titre de la carte</h5> */}
                <p class="card-text"><b>Matricule : </b></p>
              </div>
          </div>    
        
          <div className='card'> 
              <img src={'b13.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
                {/* <h5 class="card-title">Titre de la carte</h5> */}
                <p class="card-text"><b>Matricule : </b></p>
              </div>
          </div>    
            
          <div className='card'> 
              <img src={'b14.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
                {/* <h5 class="card-title">Titre de la carte</h5> */}
                <p class="card-text"><b>Matricule : </b></p>
              </div>
          </div>    
      
           <div className='card'> 
                <img src={'b15.png'} className="card-img-top" alt='ghj 2' />
                <div class="card-body">
                  {/* <h5 class="card-title">Titre de la carte</h5> */}
                  <p class="card-text"><b>Matricule : </b></p>
                </div>   
          </div>          
      
        </div>

        <br></br>

        <div className='card-group'> 
          
          <div className='card'> 
               <img src={'b16.png'} className="card-img-top" alt='ghj 2' />
               <div class="card-body">
                 {/* <h5 class="card-title">Titre de la carte</h5> */}
                 <p class="card-text"><b>Matricule : </b></p>
               </div>
          </div>    
           
          <div className='card'> 
               <img src={'b17.png'} className="card-img-top" alt='ghj 2' />
               <div class="card-body">
                 {/* <h5 class="card-title">Titre de la carte</h5> */}
                 <p class="card-text"><b>Matricule : </b></p>
               </div>
           </div>
               
           <div className='card'> 
               <img src={'b18.png'} className="card-img-top" alt='ghj 2' />
               <div class="card-body">
                 {/* <h5 class="card-title">Titre de la carte</h5> */}
                 <p class="card-text"><b>Matricule : </b></p>
               </div>
           </div>
                
           <div className='card'> 
               <img src={'b19.png'} className="card-img-top" alt='ghj 2' />
               <div class="card-body">
                 {/* <h5 class="card-title">Titre de la carte</h5> */}
                 <p class="card-text"><b>Matricule : </b></p>
               </div>             
           </div>
     
        </div>
        <br></br>
        <div className='card-group'>     
          <div className='card'> 
               <img src={'b20.png'} className="card-img-top" alt='ghj 2' />
               <div class="card-body">
                 {/* <h5 class="card-title">Titre de la carte</h5> */}
                 <p class="card-text"><b>Matricule : </b></p>
               </div>
          </div>    
        
          <div className='card'> 
              <img src={'b21.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
                {/* <h5 class="card-title">Titre de la carte</h5> */}
                <p class="card-text"><b>Matricule : </b></p>
              </div>
          </div>
        
          <div className='card'> 
              <img src={'b22.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
                {/* <h5 class="card-title">Titre de la carte</h5> */}
                <p class="card-text"><b>Matricule : </b></p>
              </div>
          </div>
               
          <div className='card'> 
              <img src={'b23.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
                {/* <h5 class="card-title">Titre de la carte</h5> */}
                <p class="card-text"><b>Matricule : </b></p>
              </div>             
          </div>
       
        </div>
        <br></br>
        <div className='card-group'> 
         
          <div className='card'> 
               <img src={'b24.png'} className="card-img-top" alt='ghj 2' />
               <div class="card-body">
                 {/* <h5 class="card-title">Titre de la carte</h5> */}
                 <p class="card-text"><b>Matricule : </b></p>
               </div>
          </div>    
          
          <div className='card'> 
              <img src={'b25.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
              <p class="card-text"><b>Matricule : </b></p>
              </div>
          </div>
              
          <div className='card'> 
              <img src={'b26.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
                {/* <h5 class="card-title">Titre de la carte</h5> */}
                <p class="card-text"><b>Matricule : </b></p>
              </div>
          </div>
            
          <div className='card'> 
              <img src={'b27.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
                {/* <h5 class="card-title">Titre de la carte</h5> */}
                <p class="card-text"><b>Matricule : </b></p>
              </div>             
          </div>
     
        </div>
        <br></br>

        <div className='card-group'> 
         
          <div className='card'> 
               <img src={'b28.png'} className="card-img-top" alt='ghj 2' />
               <div class="card-body">
               <p class="card-text"><b>Matricule : </b></p>
               </div>
          </div>    
             
          <div className='card'> 
              <img src={'b29.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
              <p class="card-text"><b>Matricule : </b></p>
              </div>
          </div>
            
          <div className='card'> 
              <img src={'b30.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
              <p class="card-text"><b>Matricule : </b></p>
              </div>
          </div>
                
          <div className='card'> 
              <img src={'b31.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
              <p class="card-text"><b>Matricule : </b></p>
              </div>             
          </div>       
        </div>

        <br></br>

        <div className='card-group'> 

          <div className='card'> 
               <img src={'b32.png'} className="card-img-top" alt='ghj 2' />
               <div class="card-body">
               <p class="card-text"><b>Matricule : </b></p>
               </div>
          </div>    
    
          <div className='card'> 
              <img src={'b33.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
              <p class="card-text"><b>Matricule : </b></p>
              </div>
          </div>
          
          <div className='card'> 
              <img src={'b34.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
              </div>
          </div>
        
          <div className='card'> 
              <img src={'b35.png'} className="card-img-top" alt='ghj 2' />
              <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
              </div>             
          </div>
              
        </div>
        <br></br>

        <div className='card-group'> 
           
               <div className='card'> 
                    <img src={'b36.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>    
                       
                <div className='card'> 
                    <img src={'b37.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>
                     
                <div className='card'> 
                    <img src={'b38.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>
                 
                <div className='card'> 
                    <img src={'b39.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>             
                </div>
            </div>
       
        <br></br>
        <div className='card-group'> 
          
               <div className='card'> 
                    <img src={'b40.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>    
             
                <div className='card'> 
                    <img src={'b41.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>
                     
                <div className='card'> 
                    <img src={'b42.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>
                       
                <div className='card'> 
                    <img src={'b43.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>             
                </div>           
        </div>
        <br></br>

        <div className='card-group'> 
        
               <div className='card'> 
                    <img src={'b44.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>    
               
                <div className='card'> 
                    <img src={'b45.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>
            
                <div className='card'> 
                    <img src={'b46.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>
             
                <div className='card'> 
                    <img src={'b47.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>             
                </div>
        
        </div>
        <br></br>

        <div className='card-group'> 
         
               <div className='card'> 
                    <img src={'b48.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>    
                  
                <div className='card'> 
                    <img src={'b49.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>
                 
                <div className='card'> 
                    <img src={'b50.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>
        
                <div className='card'> 
                    <img src={'b51.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>             
                </div>
        </div>
        <br></br>

        <div className='card-group'> 
        
               <div className='card'> 
                    <img src={'b52.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>    
                  
                <div className='card'> 
                    <img src={'b53.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>
          
                <div className='card'> 
                    <img src={'b54.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>
                   
                <div className='card'> 
                    <img src={'b55.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>             
                </div>
        </div>
        <br></br>

        <div className='card-group'> 
           
               <div className='card'> 
                    <img src={'b56.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>    
           
                <div className='card'> 
                    <img src={'b57.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>
         
                <div className='card'> 
                    <img src={'b58.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>
             
                <div className='card'> 
                    <img src={'b59.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>             
                </div>
        </div>
        <br></br>

        <div className='card-group'> 
       
               <div className='card'> 
                    <img src={'b60.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>    
         
                <div className='card'> 
                    <img src={'b61.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>
     

       
                <div className='card'> 
                    <img src={'b62.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>
                </div>
      

         
                <div className='card'> 
                    <img src={'b63.png'} className="card-img-top" alt='ghj 2' />
                    <div class="card-body">
                    <p class="card-text"><b>Matricule : </b></p>
                    </div>             
                </div>
     
        </div>

        <br></br>

        <div className='card-group'> 
            
          <div className='card'> 
               <img src={'b64.png'} className="card-img-top" alt='ghj 2' />
               <div class="card-body">
                <p class="card-text"><b>Matricule : </b></p>
               </div>
          </div>    
               
          <div className='card'> 
               <img src={'b65.png'} className="card-img-top" alt='ghj 2' />
               <div class="card-body">
                <p class="card-text"><b>Matricule : </b></p>
               </div>
          </div>         
        </div>

        <div className="container mt-5">
              <div className="d-flex justify-content-center">
                <button onClick={() => setButtonPopup(true)} id="reservation-btn" type="button" className="my-button btn btn-primary">Réserver Maintenant</button>   
              </div>            
        </div>

    </div>

    {/* <Popup trigger={buttonPopup} setTrigger=
            {setButtonPopup} > 
          <h1>my pop up </h1>
          <div className="input-box">
              <form action="https://formsubmit.co/yousramsaouri13@gmail.com" method="POST" id="myForm">
              <div className="formbx">
                <div className="input-filed">
                  <input id="name" type="text" className="input" name="name"  required />
                  <label htmlFor="name">Nom et Prénoms : </label>
                </div>
                <div className="input-filed">
                <input id="tel" type="tel" className="input" name="number" required />
                  <label htmlFor="tel">Votre Teléphone : </label>
                </div>
                <div className="input-filed">
                  <input id="adresse" type="text" className="input" name="loca" required />
                  <label htmlFor="adresse">Votre adresse : </label>
                </div>
                <div className="input-filed">
                <textarea id="body" className="input" name="message" required/>
                  <label htmlFor="nabodyme">Votre Message : </label>
                </div>
                <input type="hidden" name="_captcha" value='false'/>
                <input type="hidden" name="_next" value='http://localhost:3000/thanks'/>
                <input onclick="sendEmail()" type="submit" className="submit" value="envoyer"/>
              </div>
              </form> 
            </div> 
        </Popup> */}
        
        <Footer />
  </div>
   
  )
}

export default Shop
