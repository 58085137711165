import React from 'react'
import { Route, Routes } from 'react-router-dom' 
import Home from './routes/Home'
import Contact from './routes/Contact'
import Urgent from './routes/Urgent'
import Thanks from './routes/Thanks'
import Réclamation from './routes/Réclamation'
import Charpenterie from './routes/Charpenterie'
import Maintenance from './routes/Maintenance'
import Electricite from './routes/Electricite'
import Decore from './routes/Decore'
import Construction from './routes/Construction'
import Peinture from './routes/Peinture'
import Ménage from './routes/Ménage'
import Securite from './routes/Securite'
import SécurutéIntégré from './routes/SécurutéIntégré'
import InstallationElectrique from './routes/InstallationElectrique'
import SignUp from './routes/SignUp'
import Shop from './routes/Shop'
import Boutique from './routes/Boutique'
import Footer from './components/Footer'



export default function App() {
  return (
    <div className='App'>  
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/urgent" element={<Urgent/>} />
        <Route path="/reclamation" element={<Réclamation/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/charpenterie" element={<Charpenterie/>} />
        <Route path='/maintenance' element={<Maintenance />} />
        <Route path='/électricité' element={<Electricite/>} />
        <Route path='/décore' element={<Decore />} />
        <Route path='/thanks' element={<Thanks />} />
        <Route path='/construction' element={<Construction />} />
        <Route path='/peinture' element={<Peinture />} />
        <Route path='/ménage' element={<Ménage />} />
        <Route path='/sécurité' element={<Securite />} />
        <Route path='/sécuruté_intégré' element={<SécurutéIntégré />} />
        <Route path='/signup' element={< SignUp/>} />
        <Route path='/shop' element={< Shop/>} />
        <Route path='/boutique' element={< Boutique/>} />
        <Route path='/installation_électrique' element={<InstallationElectrique/>} />
   
      </Routes> 
      {/* <Footer/> */}
        
    </div>
  )
}

