import React from 'react'
import Maint from "../assets/maint.jpg";
import charp from "../assets/char.jpg";
import elec from "../assets/elec.jpg";
import sec1 from "../assets/men3.png";
import sec11 from "../assets/sec1.jpg";
import sec2 from "../assets/sec7.png";
import construction from "../assets/construction.jpg";
import peinture from "../assets/peinture.jpg";
import insta from "../assets/inta4.jpg";
import dec from "../assets/dec13.png";
import "./SerStyles.css";

function Services() {
  return (
    <div className='container cl'>
      <p>
        La société H.B AFRICA spécialisés dans l’installation d’équipement
        électriques, l’installation de caméras de surveillance et systèmes
        d’alarmes, la maintenance, la charpenterie, la construction,
        l’électricité, la peinture, le platrage ainsi que le gardiennage et
        ménage. nous sommes dédiés à assurer la sécurité et tranquillité de nos clients.
              </p>
        <h1>Rececents Services</h1>
        <p>Vous trouverez ici les meilleurs services que vous cherchez</p>
        <div class="row">
          <div class="col-sm-4 mb-3 mb-sm-0">
            <div class="card">
              <div class="card-body text-center">
              <div className='d-flex justify-content-between text-center'>
                <h5 class="card-title">Maintenance</h5><h4>الصيانة</h4>
              </div>
                <img src={Maint} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
                <a href="../maintenance" class="btn btn-primary custom-btn">Voir Plus</a>          
              </div>
            </div>
          </div>

          <div class="col-sm-4 mb-3 mb-sm-0">
            <div class="card">
              <div class="card-body text-center">
              <div className='d-flex justify-content-between text-center'>
                <h5 class="card-title">Charpenterie</h5><h4>النجارة</h4>
               </div> 
                <img src={charp} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
                <a href="../charpenterie" class="btn btn-primary custom-btn">Voir Plus</a>          
              </div>
            </div>
          </div>

          <div class="col-sm-4 mb-3 mb-sm-0">
            <div class="card">
              <div class="card-body text-center ">
                <div className='d-flex justify-content-between text-center'>
                <h5 class="card-title custom-card-title">Construction</h5> <h4>البناء</h4>
                </div>
                <img src={construction} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
                <a href="../construction" class="btn btn-primary custom-btn">Voir Plus</a>          
              </div>
            </div>
          </div>

        </div>
        <br></br>
        <div class="row">
          <div class="col-sm-4 mb-3 mb-sm-0">
            <div class="card">
              <div class="card-body text-center">
              <div className='d-flex justify-content-between text-center'>
                <h5 class="card-title">Eléctricité</h5><h4>الكهرباء</h4>
                </div>
                <img src={elec} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
                <a href="../électricité" class="btn btn-primary custom-btn">Voir Plus</a>          
              </div>
            </div>
          </div>

          <div class="col-sm-4 mb-3 mb-sm-0">
            <div class="card">
              <div class="card-body text-center">
              <div className='d-flex justify-content-between text-center'>
                <h5 class="card-title">Peinture</h5><h4>الصباغة</h4>
                </div>
                <img src={peinture} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
                <a href="../peinture" class="btn btn-primary custom-btn">Voir Plus</a>          
              </div>
            </div>
          </div>

          <div class="col-sm-4 mb-3 mb-sm-0">
            <div class="card">
              <div class="card-body text-center">
              <div className='d-flex justify-content-between text-center'>
                <h5 class="card-title">Instalation Eléctrique</h5><h4>التركيبات الكهربائية</h4>
                </div>
                <img src={insta} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
                <a href="../installation_électrique" class="btn btn-primary custom-btn">Voir Plus</a>          
              </div>
            </div>
          </div>

        </div>
        <br></br>
        <div class="row">
          <div class="col-sm-4 mb-3 mb-sm-0">
            <div class="card">
              <div class="card-body text-center">
              <div className='d-flex justify-content-between text-center'>
                <h5 class="card-title">Ménage</h5><h4> التنظيف</h4>
                </div>
                <img src={sec1} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
                <a href="../Ménage" class="btn btn-primary custom-btn">Voir Plus</a>          
              </div>
            </div>
          </div>

          <div class="col-sm-4 mb-3 mb-sm-0">
            <div class="card">
              <div class="card-body text-center">
              <div className='d-flex justify-content-between text-center'>
                <h5 class="card-title">Sécurité Intégré</h5><h4>الأمان المتكامل</h4>
                </div>
                <img src={sec2} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
                <a href="../sécuruté_intégré" class="btn btn-primary custom-btn">Voir Plus</a>          
              </div>
            </div>
          </div>

          <div class="col-sm-4 mb-3 mb-sm-0">
            <div class="card">
              <div class="card-body text-center">
              <div className='d-flex justify-content-between text-center'>
                <h5 class="card-title">Platrage </h5><h4>الجبص</h4>
                </div>
                <img src={dec} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
                <a href="../décore" class="btn btn-primary custom-btn">Voir Plus</a>          
              </div>
            </div>
          </div>

        </div>

        <br></br>

        <div class="row">
          <div class="col-sm-4 offset-sm-4 mb-3 mb-sm-0">
            <div class="card">
              <div class="card-body text-center">
              <div className='d-flex justify-content-between text-center'>
                <h5 class="card-title">Gardiennage </h5><h4>الامن</h4>
                </div>
                <img src={sec11} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
                <a href="../sécurité" class="btn btn-primary custom-btn">Voir Plus</a>          
              </div>
            </div>
          </div>

        </div>


    </div>
  )
}

export default Services
