import React from 'react'
import Hero from "../components/Hero";
import ele from "../assets/elec.jpg";
import  Navbar from "../components/Navbar";
import { useState } from "react";
import Popup from "../components/Popup";
import "../index.css";
import Footer from '../components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


function Electricite() {
  const [buttonPopup, setButtonPopup] = useState(false);
  return (
    <div className='fixed-container'>
       <Navbar/>
        <Hero 
         cName="serv"
         HeroImg={ele}
         title="Electricité"
         text=""
         buttonText="Urgent"
         url="/urgent"
         btnClass="show"
        />
        <br></br><br></br>
       
        <div className="container">
       
              <p><b>H.B Africa </b>vous présente un service d'<b>électricité</b> qui assure des installations fiables et sûres, répondant à vos besoins résidentiels et commerciaux. Contactez-nous pour des solutions électriques professionnelles et efficaces.</p>
                <div className="card-group">
          
                    <div className="card">
                      <img src={'elec1.png'} className="card-img-top img-fluid d-block w-100 equal-img" alt='fgh 1' />                  
                    </div>
              
                  <div className="card">
                    <img src={'elec2.png'} className="card-img-top img-fluid d-block w-100 equal-img" alt='ghj 2' />
                  </div>
              
                  <div className="card">
                    <img src={'elec3.png'} className="card-img-top img-fluid d-block w-100 equal-img" alt='fgh 3' />
                  </div>
        
                </div>

          <br></br>
        </div>

        <div class="container mt-5">
            <div class="d-flex justify-content-center">
            <button onClick={() => setButtonPopup(true)} id="reservation-btn" type="button" className="my-button btn btn-primary">Réserver Maintenant</button>   
            </div>             
        </div>

        <Popup trigger={buttonPopup} setTrigger=
            {setButtonPopup} > 
          <h3>Réserver Service d'électricité </h3>
          <div className="input-box">
              <form action="https://formsubmit.co/contact@hbafrica.ma" method="POST" id="myForm">
              <div className="formbx">
                <div className="input-filed">
                  <input id="name" type="text" className="input" name="name"  required />
                  <label htmlFor="name">Nom et Prénoms : </label>
                </div>
                <div className="input-filed">
                <input id="tel" type="tel" className="input" name="number" required />
                  <label htmlFor="tel">Votre Teléphone : </label>
                </div>
                <div className="input-filed">
                  <input id="adresse" type="text" className="input" name="localisation" required />
                  <label htmlFor="adresse">Votre adresse : </label>
                </div>
                <div className="input-filed">
                              <select id="service" className="input" name="service" required>
                                <option value="Electricite">Electricite </option>                               
                              </select>
                              <label htmlFor="service">Sélectionnez le service :</label>
                            </div>
                <input type="hidden" name="_captcha" value='false'/>
                <input type="hidden" name="_next" value='https://www.hbafrica.ma/thanks'/>
                <input onclick="sendEmail()" type="submit" className="submit" value="envoyer"/>
              </div>
              </form> 
            </div> 
        </Popup>
    



        
     
        <Footer />
    </div>
  )
}
export default Electricite;
