import Hero from "../components/Hero";
import  Navbar from "../components/Navbar"
import reclamation from "../assets/rec.jpg"
import "../index.css";
import Footer from "../components/Footer";


function Réclamation (){
    return(
       <div className="fixed-container" style={{textAlign:'center'}} >
        <Navbar/>
        <Hero 
         cName="serv"
         HeroImg={reclamation}
         title="Réclamation"
         text=""
         buttonText="Urgent"
         url="/urgent"
         btnClass="show"
        />
        <br></br>
        <h2>Service Client : Nous Sommes Là Pour Vous Aider</h2>
        <p>Nous sommes sincèrement désolés d'apprendre que vous avez rencontré des problèmes avec nos services. La satisfaction de nos clients est notre priorité absolue.
        Votre expérience est importante pour nous, et nous souhaitons résoudre cette situation de manière rapide et efficace. 
        Veuillez nous fournir plus de détails sur votre réclamation en remplissant le formulaire ci-dessous.Notre équipe dédiée se penchera attentivement sur votre cas 
        et fera tout son possible pour trouver une solution satisfaisante. Merci de nous donner l'opportunité de rectifier cette situation et de regagner votre confiance.</p>
         
        <br></br>

        <div className="wrapper">
          <div className="container" >

            <div className="input-box">
              <form action="https://formsubmit.co/contact@hbafrica.ma" method="POST" id="myForm">
              <div className="formbx">
                <div className="input-filed">
                  <input id="name" type="text" className="input" name="name"  required />
                  <label htmlFor="name">Nom et Prénoms : </label>
                </div>
                <div className="input-filed">
                <input id="tel" type="tel" className="input" name="number" required />
                  <label htmlFor="tel">Votre Teléphone : </label>
                </div>
                <div className="input-filed">
                  <input id="adresse" type="text" className="input" name="loca" required />
                  <label htmlFor="adresse">Votre adresse : </label>
                </div>
                <div className="input-filed">
                <textarea id="body" className="input" name="message" required/>
                  <label htmlFor="nabodyme">Votre Message : </label>
                </div>
                <input type="hidden" name="_captcha" value='false'/>
                <input type="hidden" name="_next" value='https://www.hbafrica.ma/thanks'/>
                <input onclick="sendEmail()" type="submit" className="submit" value="envoyer"/>
              </div>
              </form> 
            </div> 

             </div>
            </div>
          <Footer />
       
       </div>
    )   
}


export default Réclamation;