import React from 'react'
import  Navbar from "../components/Navbar";
import "../index.css";
import Footer from '../components/Footer';


function Boutique() {
  
  return (
    <div className='fixed-container'>
    <Navbar/>
 
     <br></br><br></br><br></br><br></br><br></br><br></br>
  
    <img src={'3.jpg'} className="image-boutique" alt='ghj 2' />
  
     <Footer />
</div>
  )
}

export default Boutique
