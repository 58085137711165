import React from 'react'
import Hero from "../components/Hero";
import  Navbar from "../components/Navbar";
import { useState } from "react";
import Popup from "../components/Popup";
import sec from "../assets/sec1.jpg";
import "../index.css";
import Footer from '../components/Footer';

function Securite() {
  const [buttonPopup, setButtonPopup] = useState(false);
  return (
    <div className='fixed-container'>
        <Navbar/>
        <Hero 
         cName="serv"
         HeroImg={sec}
         title="Gardiennage"
         text=""
         buttonText="Urgent"
         url="/urgent"
         btnClass="show"
        />
        <br></br><br></br>
        <div className="container">
       
         <p>les agents de <b>sécurité</b> hautement qualifiés chez <b>H.B Africa </b> assurent 
         une protection proactive et une <b>surveillance</b> experte pour garantir la sécurité de vos 
         biens et de vos espaces. Avec un engagement inébranlable envers la sécurité, 
         nous offrons des services fiables pour une tranquillité d'esprit totale.</p>
          
           <div className="card-group">
         
                 <div className="card">
                   <img src={'sec9.png'} className="card-img-top" alt='fgh 1' />                  
                 </div>
                    
               <div className="card">
                 <img src={'sec8.png'} className="card-img-top img-fluid d-block w-100" alt='ghj 2' />
               </div>

               <div className="card">
                 <img src={'sec10.png'} className="card-img-top img-fluid d-block w-100" alt='ghj 2' />
               </div>
                             
           </div>

        </div>
         
        <div class="container mt-5">
          <div class="d-flex justify-content-center">
            <button onClick={() => setButtonPopup(true)} id="reservation-btn" type="button" className="my-button btn btn-primary">Réserver Maintenant</button>   
          </div>               
        </div>  

        <Popup trigger={buttonPopup} setTrigger=
            {setButtonPopup} > 
          <h3>Réserver Service de Gardiennage</h3>
          <div className="input-box">
              <form action="https://formsubmit.co/contact@hbafrica.ma" method="POST" id="myForm">
              <div className="formbx">
                <div className="input-filed">
                  <input id="name" type="text" className="input" name="name"  required />
                  <label htmlFor="name">Nom et Prénoms : </label>
                </div>
                <div className="input-filed">
                <input id="tel" type="tel" className="input" name="number" required />
                  <label htmlFor="tel">Votre Teléphone : </label>
                </div>
                <div className="input-filed">
                  <input id="adresse" type="text" className="input" name="localisation" required />
                  <label htmlFor="adresse">Votre adresse : </label>
                </div>
                <div className="input-filed">
                              <select id="service" className="input" name="service" required>
                                <option value="Gardiennage">Gardiennage </option>                               
                              </select>
                              <label htmlFor="service">Sélectionnez le service :</label>
                            </div>
                <input type="hidden" name="_captcha" value='false'/>
                <input type="hidden" name="_next" value='https://www.hbafrica.ma/thanks'/>
                <input onclick="sendEmail()" type="submit" className="submit" value="envoyer"/>
              </div>
              </form> 
            </div> 
          </Popup> 
        <Footer />
    </div>
  )
}

export default Securite
