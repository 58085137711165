import React from 'react'
import Hero from "../components/Hero";
import pein from "../assets/peinture.jpg";
import  Navbar from "../components/Navbar";
import { useState } from "react";
import Popup from "../components/Popup";
import "../index.css";
import Footer from '../components/Footer';


function Peinture() {
  const [buttonPopup, setButtonPopup] = useState(false);
  return (
    <div className='fixed-container'>
       <Navbar/>
        <Hero 
         cName="serv"
         HeroImg={pein}
         title="Peinture"
         text=""
         buttonText="Urgent"
         url="/urgent"
         btnClass="show"
        />
        <br></br><br></br>
        <div className="container">
      
                <p><b>H.B Africa </b> vous présente un service de <b>Peinture</b> qui peut se démarquer en offrant une combinaison d'expertise, de qualité, de fiabilité et d'engagement envers la satisfaction de nos fidéles clients, en se basant sur :
                  <ul>
                  ◇Expertise Professionnelle. <br></br>
                  ◇Conseils Personnalisés .<br></br>
                  ◇Utilisation de Produits de Qualité. <br></br>
                  ◇Respect des Délais. <br></br>
                  ◇Références et Témoignages .<br></br>
                  ◇Assistance en cas d’urgence.<br></br>
                  ◇Engagement envers la Satisfaction Client.
                  </ul>
                </p>
                <div className="card-group">
              
                    <div className="card">
                      <img src={'peint0.png'} className="card-img-top img-fluid d-block w-100" alt='fgh 1' />                  
                    </div>
            
                  <div className="card">
                    <img src={'peint1.png'} className="card-img-top img-fluid d-block w-100" alt='ghj 2' />
                  </div>
                 
                  <div className="card">
                    <img src={'peint2.png'} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
                  </div>
            
                </div>

        <br></br>

        <div className="card-group">
           
            <div className="card">
              <img src={'peint3.png'} className="card-img-top img-fluid d-block w-100" alt='fgh 1' />
            </div>
         
            <div className="card">
              <img src={'peint4.png'} className="card-img-top img-fluid d-block w-100" alt='ghj 2' />
            </div>
           
            <div className="card">
              <img src={'peint5.png'} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
            </div>
      
        </div>
        <br></br>
        <div className="card-group">
               
            <div className="card">
              <img src={'peint6.png'} className="card-img-top img-fluid d-block w-100" alt='fgh 1' />
            </div>
        
            <div className="card">
              <img src={'peint7.png'} className="card-img-top img-fluid d-block w-100" alt='ghj 2' />
            </div>
        
            <div className="card">
              <img src={'peint8.png'} className="card-img-top img-fluid d-block w-100" alt='fgh 3' />
            </div>
      
        </div>
      </div>  

        <div class="container mt-5">
              <div class="d-flex justify-content-center">
                <button onClick={() => setButtonPopup(true)} id="reservation-btn" type="button" className="my-button btn btn-primary">Réserver Maintenant</button>   
              </div>          
        </div>
         
        <Popup trigger={buttonPopup} setTrigger=
            {setButtonPopup} > 
          <h3>Réserver Service de Peinture</h3>
          <div className="input-box">
              <form action="https://formsubmit.co/contact@hbafrica.ma" method="POST" id="myForm">
              <div className="formbx">
                <div className="input-filed">
                  <input id="name" type="text" className="input" name="name"  required />
                  <label htmlFor="name">Nom et Prénoms : </label>
                </div>
                <div className="input-filed">
                <input id="tel" type="tel" className="input" name="number" required />
                  <label htmlFor="tel">Votre Teléphone : </label>
                </div>
                <div className="input-filed">
                  <input id="adresse" type="text" className="input" name="localisation" required />
                  <label htmlFor="adresse">Votre adresse : </label>
                </div>
                <div className="input-filed">
                              <select id="service" className="input" name="service" required>
                                <option value="Peinture">Peinture </option>                               
                              </select>
                              <label htmlFor="service">Sélectionnez le service :</label>
                            </div>
                <input type="hidden" name="_captcha" value='false'/>
                <input type="hidden" name="_next" value='https://www.hbafrica.ma/thanks'/>
                <input onclick="sendEmail()" type="submit" className="submit" value="envoyer"/>
              </div>
              </form> 
            </div> 
        </Popup> 
         
        <Footer />
    </div>
  )
}
export default Peinture;
